<template>
  <div class="d-flex flex-row justify-items-center">
    <div class="border-right border-light text-right w-50">
      <img class="mr-5" src="../../assets/logo.svg" alt="Toast Lettings" width="150">
    </div>
    <div class="align-self-center d-flex flex-row justify-items-center mb-0 ml-5 text-left text-light w-50">
        <b-spinner class="mr-3" variant="light" /><span class="font-weight-normal h4">Logging out...</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted() {
    this.logout().then(() => {
      this.$router.push('/auth/login')
    });
  },
  methods: {
    ...mapActions('auth', ['logout']),
  }
}
</script>

<style>

</style>
